// Colors 

$white: #fff;
$light_grey: #d7d7d7;
$mid_grey: #ccc;
$highlight: #ef225a;
$highlight_mix_on_base: #ed19bf;
$highlight_mix_on_dark_base: #da19a0;
$highlight_focus_dark_base: red;
$base: #23168c;
$dark_base: #0F0757;
$darkest_base: #070139;
$dark_base_transparent: #07013999;
$black: #000;


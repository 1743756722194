@import './utils/scss/animations.scss';
@import './utils/scss/colors.scss';


@font-face {
  font-family: 'PPNeueMachina-InktrapUltraBold';
  src:  url('./assets/fonts/PPNeueMachina-InktrapUltrabold.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMachina-InktrapUltrabold.woff') format('woff');
}

@font-face {
  font-family: 'PPNeueMachina-InktrapLight';
  src:  url('./assets/fonts/PPNeueMachina-InktrapLight.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMachina-InktrapLight.woff') format('woff');
}

@font-face {
  font-family: 'PPNeueMachina-InktrapMedium';
  src:  url('./assets/fonts/PPNeueMachina-InktrapMedium.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMachina-InktrapMedium.woff') format('woff');
}

// Default & Mobile XS
@media (min-width: 0px) {

  * {
    box-sizing: border-box;
  }

  body,
  #root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: $base;
  }

  .mob {
    width: 100%;
  }

  p, a, h1, h2, h3, h4, h5, h6 {
    color: $white;
  }

  body {
    font-family: PPNeueMachina-InktrapLight, montserrat, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
  }

  .inactive {
    visibility: hidden;

    .content {
      opacity: 0;
      transition: all 0.8s ease-in-out;

      &.top.left {
        left: 0;
        top: 50px;
      }

      &.bottom.left {
        left: 60px;
        bottom: 15px;
      }

      &.top.right {
        left: 0;
        right: unset;
        top: 50px;
      }

      &.bottom.right {
        left: 60px;
        right: unset;
        bottom: 15px;
      }

      &.center {
        transform: translate(-50%, -55%);
      }

      &.headline {
        transform: translate(-50%, -55%) scale(1.1);
      }
    }
  }
  
  .active {
    .content {
      opacity: 1;
      transition: all 0.8s 1s ease-in-out;

      &.top.left, 
      &.bottom.left {
        left: 10%;
      }
      
      &.top.right,
      &.bottom.right {
        left: 10%;
        right: unset;
      }
      
      &.center {
        transform: translate(-50%, -50%);
      }

      &.headline {
        transform: translate(-50%, -55%) scale(1);
      }
    }
  }

  .top {
    top: 50px;
  }

  .bottom {
    bottom: 15px;
  }

  .content {
    z-index: 2;
    pointer-events: none;
    position: absolute;
  }

  #intro {
    .headline {
      top: 50%;
      left: 50%;

      h1 {
        text-align: center;
        font-size: 4em;
        font-family: PPNeueMachina-InktrapUltraBold, montserrat, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
        segoe ui, arial, sans-serif;;
        font-weight: 900;
        color: $highlight;
        animation: intro 1s ease-out 1;
      }
    }
  }

  p {
    color: $white;
    font-size: 0.9em;
    line-height: 1.2em;
    text-align: justify;
  }

  .third {
    width: 80%;
  }

  .full {
    width: 90%;
  }
}

// Mobile
@media (min-width: 380px) {

  h1 {
    font-size: 5em;
  }

  p {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .inactive {
    .content {
      &.top.left {
        top: 70px;
      }

      &.top.right {
        top: 70px;
      }

      &.bottom.left {
        bottom: 50px;
      }

      &.bottom.right {
        bottom: 50px;
      }
    }
  }

  .top {
    top: 70px;
  }

  .bottom {
    bottom: 50px;
  }
}

// Tablet
@media (min-width: 768px) {

  .left {
    left: 50px;
  }

  .top {
    top: 50px;
  }

  .right {
    right: 50px;
    left: unset;
  }

  .inactive {
    .content {
      &.top.left, 
      &.bottom.left {
        left: 0px;
        right: unset;
      }

      &.top.right,
      &.bottom.right {
        left: unset;
        right: 0px;
      }

      &.top.left,
      &.top.right {
        top: 50px;
      }
    }
  }

  .active {
    .content {
      &.top.left, 
      &.bottom.left {
        left: 30px;
        right: unset;
      }

      &.top.right,
      &.bottom.right {
        left: unset;
        right: 30px;
      }
    }
  }

  .bottom {
    bottom: 50px;
  }

  p {
    font-size: 1.4em;
  }

  #intro .headline h1 {
    font-size: 7em;
  }

  .third {
    width: 50%;
  }
}

@media (min-width: 1200px) {

  .third {
    width: 30%;
  }

  .active {
    .content {
      &.top.left, 
      &.bottom.left {
        left: 50px;
        right: unset;
      }
      
      &.top.right,
      &.bottom.right {
        left: unset;
        right: 50px;
      }
    }
  }
}
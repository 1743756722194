@import './../../utils/scss/colors.scss';


:root {
  --tooltip-arrow-size: 10px;
}

/* Wrapping */
.tooltip {
  display: inline-block;
  position: relative;
  pointer-events: all;

  sup {
    font-size: 0.8em;
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  &::after,
  &::before {
    --scale: 0;
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translateX(-50%) translateY(var(--tooltip-translate-y, 0)) scale(var(--scale));
    transition:  150ms transform;
  }

  &::after {
    --tooltip-translate-y: var(--tooltip-arrow-size); 
    content: attr(data-tooltip);
    background-color: $darkest_base;
    border-radius: 5px;
    color: $white;
    font-size: 0.8em;
    line-height: 1.5em;
    padding: 16px;
    width: max-content;
    max-width: 120%;
    text-align: center;
    transform-origin: top center;
  }

  &::before {
    content: '';
    border: var(--tooltip-arrow-size) solid transparent;
    border-bottom-color: $darkest_base;
    --tooltip-translate-y: calc(-1 * var(--tooltip-arrow-size) + 1px);
    transform-origin: bottom center;
  }

  &:hover::after,
  &:hover::before {
    --scale: 1;
  }
}


// Tablet
@media (min-width: 768px) {

  .tooltip {
    &::after {
      font-size: 0.7em;
      line-height: 1.5em;
      max-width: 110%;
    }
  }
}


// Desktop
@media (min-width: 1280px) {

  .tooltip {
    &::after {
      font-size: 0.65em;
      line-height: 1.8em;
      max-width: 130%;
    }
  }
}
@import './../../utils/scss/colors.scss';

// CURSOR

body {
  cursor: none;
}

.cursor-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: $highlight_mix_on_base;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 999;
  mix-blend-mode: difference;
  translate: -50% -50%;
  pointer-events: none;
  transition: width 0.4s ease, height 0.4s ease;
  transform: translate3d(-100px, 0px, 0px);

  span {
    color: $black;
    align-self: center;
    text-align: center;
    font-size: 0.8em;
  }
} 

.modal:hover ~ .cursor-circle {
  width: 60px;
  height: 60px;
  transition: width 0.4s ease, height 0.4s ease;
  background-color: $highlight_mix_on_dark_base;

  span {
    display: none;
  }
}


// .modal-open #contact-btn:hover ~ .cursor-circle {
//   background-color: #da19b0;
// }

button:hover ~ .cursor-circle,
.modal:has(.modal-cta:hover) ~ .cursor-circle,
.modal:has(a:hover) ~ .cursor-circle, 
section:has(.modal-cta:hover) ~ .cursor-circle,
section:has(a:hover) ~ .cursor-circle,
section:has(.tooltip:hover) ~ .cursor-circle {
  width: 30px;
  height: 30px;
  transition: width 0.4s ease, height 0.4s ease;
  background-color: $highlight_mix_on_base;

  span {
    display: none;
  }
}

// .modal-open:hover {
//   .cursor-circle {
//     span {
//       display: none;
//     }
//   }
// }

.modal-cta:hover {
  cursor: pointer;
}

//TODO check out if there is pink being generated

// section .content:has(a:hover) {
//   color: pink;
// }

.modal-cta {
  pointer-events: all;
  background: 
    linear-gradient(to right, $light_grey, $light_grey),
    linear-gradient(to right, $white , $white);
  background-size: 100% 0.07em, 0 0.07em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.modal-cta:hover {
  transition: all 0.5s ease;
  background-size: 0 0.1em, 100% 0.1em;
}
@import './../../utils/scss/animations.scss';
@import './../../utils/scss/colors.scss';

// Default & Mobile
@media (min-width: 0px) {

  #contact-btn {
    position: absolute;
    right: calc(10% - 15px);
    top: 20px;
    z-index: 999;
    background: transparent;
    border: none;
    color: $light_grey;
    font-weight: 400;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    transition: color 0.4s ease;
    font-family: PPNeueMachina-InktrapMedium, montserrat, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
    animation: lateFadeIn 1.2s ease-out;

    svg {
      stroke: $light_grey;
      position: relative;
      top: -0.1em;
      height: 25px;
      width: 25px;
    }

    span {
      padding-right: 10px;
      animation: moveIn 1000ms ease-in forwards;
    }

    @media (hover: hover) {
      &:hover {
        color: $white;
        cursor: pointer;
    
        svg {
          stroke: $white;
        }
  
        .line1, .line2 {
          stroke-dashoffset: -10;
          stroke-dasharray: 50 1000;
        }
      }
    }

    .line {
      transition: 0.7s;
    }

    .line1 {
      transition: 0.7s;
      stroke-dasharray: 75 1000;
    }

    .line2 {
      transition: 0.7s;
      stroke-dasharray: 45 1000;
    }

    .text-container {
      width: 120px;
      height: 20px;
      overflow: hidden;
      transition: 500ms ease-in-out;

      span {
        white-space: nowrap;
      }
    }

    &.open {
      &:hover {
        .line1, .line2 {
          stroke-dashoffset: -160;
          stroke-dasharray: 50 1000;
        }
      }
    
      .line1, .line2 {
        stroke-dashoffset: -150;
        stroke-dasharray: 70 1000;
      }

      .text-container {
        width: 0px;
      }
    }
  }

  .modal {
    background-color: $dark_base_transparent;
    backdrop-filter: blur(8px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 9;
    display: grid;
    place-items: center;
    opacity: 0;
    visibility: hidden;
    animation: fadeOut 1500ms forwards;
    transition: visibility 1s;

    ::selection {
      background-color: $highlight; 
    }

    h2 {
      color: $highlight;
      font-size: 2.5em;
      position: relative;
      margin: 0;
    }

    p {
      color: $white;
      font-size: 0.9em;
      font-style: oblique;
      font-weight: 400;
      margin-bottom: 3em;
    }

    .credits-disclaimer {
      position: absolute;
      bottom: 85px;
      margin: 0 10vw;

      p { 
        font-size: 0.75em;
        color: $white;
        opacity: 0.5;
        margin: 0;
      }

      a {
        display: inline;
        font-size: 1em;
        text-decoration: underline;
      }
    }

    a {
      position: relative;
      display: block;
      color: $white;
      padding: 10px 0;
      font-size: 1.2em;
      font-weight: 500;
      text-decoration: none;
      transform: translate(0px, 0px);
      transition: transform 0.4s ease-in-out;
      width: fit-content;
    }

    @media (hover: hover) {
      a:hover {
        position: relative;
        transform: translate(5px, 0px);
        transition: transform 0.4s ease-in-out;
      }
    }

    .extLinks {
      margin-top: 45px;
      
      a {
        font-size: 0.8em;
        margin-right: 20px;
        display: block;

        @media (hover: hover) {
          &:hover {
            position: relative;
            transform: translate(0px, -2px);
            transition: transform 0.4s ease-in-out;
          }
        }
      }
    }

    &.modal-open {
      animation: fadeIn 500ms 300ms forwards;
      animation: blurIn 800ms forwards;
      opacity: 1;
      visibility: visible;

      .modal-container {
        place-self: center;
        animation: fadeIn 1000ms ease-in forwards;
        margin: 0 10vw;
        user-select: text;

        p {
          text-align: left;
        }
      }
    }

    .modal-container {
      animation: fadeOut 500ms forwards;
      transform: translateY(-10%);
    }
  }
}


@media (min-width: 380px) {
  #contact-btn {
    font-size: 1.2em;

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .modal {

    h2 {
      font-size: 3em;
    }

    p {
      font-size: 1em;
    }

    a {
      font-size: 1.5em;
    }

    .modal-container {
      transform: translateY(-15%);
    }

    .extLinks {
      margin-top: 60px;
      
      a {
        font-size: 1em;
      }
    }

    .credits-disclaimer {
      bottom: 90px;

      p { 
        font-size: 0.8em;
      }
    }
  }
}

@media (min-width: 768px) {
  #contact-btn {
    right: 12px;
  }

  .modal {

    .modal-container {
      transform: translateY(0);
    }
    .credits-disclaimer {
      bottom: 2em;
    }

    .extLinks {
      
      a {
        display: inline-block;
      }
    }
  }
}

@media (min-width: 1200px) {
  #contact-btn {
    right: 32px;
  }
}